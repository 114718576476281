@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery-icon {
    filter: drop-shadow(0 1px 1px #1a1a1a);
}

.image-gallery-icon:focus {
    outline: none;
}

.image-gallery-icon:hover {
    color: #4774FF
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 4px solid #4774FF;
}